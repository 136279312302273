import moment from "moment";
import { MouseEvent } from "react";
import { matchPath, useHistory } from "react-router";
import { API_URL } from "../constants/api";
import store from '../middleware/store';
import { DateFormat, DateFormatFormula, ProgressColor, ProgressColorHex, ProgressColorText, ProgressColorTextHex, TaskType, AutocompleteType } from "../shared/types";
import { SelectItem, TableColumn, ISort, SortDirection, ManageableTableColumn } from "../shared/interfaces";
import { getUsername } from "./user";



export function range(max: number): number[] {
  return Array.from(Array(max).fill(0),(_, i)=>i);
}



export function arrayRange(from: number, to: number, arr: any[]): any[] {
  if(arr.length <= 0 || from > to) {
    return arr
  }

  return arr.slice(from, to)
}



interface IPaginationCalculatorParams {
  currentPage: number,
  elementsPerPage: number,
  elementCount: number,
}



export function paginationCalculator(args: IPaginationCalculatorParams) {
  const {currentPage, elementsPerPage, elementCount} = args;

  let pageCount = Math.floor(elementCount / elementsPerPage) + 1;
  let elementsOnLastPage = elementCount % elementsPerPage;
  if (elementsOnLastPage===0) {
    pageCount -= 1;
    elementsOnLastPage = elementsPerPage
  }
  const isLastPage = currentPage === pageCount;
  const elementsOnCurrentPage = isLastPage ? elementsOnLastPage : elementsPerPage;
  const firstElementOnCurrentPage = (currentPage-1) * elementsPerPage + 1;
  const lastElementOnCurrentPage = firstElementOnCurrentPage + elementsOnCurrentPage - 1;

  return {
    pageCount,
    elementsOnLastPage,
    isLastPage,
    elementsOnCurrentPage,
    firstElementOnCurrentPage,
    lastElementOnCurrentPage
  }
}



export function IsNonEmptyArray(array: any) {
  return Array.isArray(array) && array.length > 0;
}



export function copyAndUpdateArr(array: any[], entries: Record<number, any>) {
  const newArray = [...array];
  for (let [key, value] of Object.entries(entries))
    newArray[key] = value;
  return newArray;
}



export function sortByKey(array: any[], orderList: any[], getter: (object: any) => any) {
  const newArray = [] as any[];
  array.forEach((o)=>{
    if (o==null) return;
    newArray[orderList.indexOf(getter(o))] = o;
  });
  return newArray;
}



export const clickFix = (e: any) : void => {
  e.stopPropagation();
}



export const getProgress = (item: any) : number => {
  return item.progress && item.progress[TaskType.Approved]
    ? item.progress[TaskType.Approved] / item.progress.total * 100
    : 0
}



export const getProgressColor = (progress: number) : string => {
  if(!progress) 
    return ProgressColorTextHex[ProgressColorText.None];
  if(progress < 33)
    return ProgressColorTextHex[ProgressColorText.Red]
  if(progress > 33 && progress < 66)
    return ProgressColorTextHex[ProgressColorText.Yellow];
  if(progress > 66)
    return ProgressColorTextHex[ProgressColorText.Green];

  return ProgressColorTextHex[ProgressColorText.None];
} 



export const getProgressBgColor = (progress: number) : string => {
  if(!progress) 
    return ProgressColorHex[ProgressColor.None];
  if(progress <= 33)
    return ProgressColorHex[ProgressColor.RedOpacity]
  if(progress > 33 && progress <= 66)
    return ProgressColorHex[ProgressColor.YellowOpacity];
  if(progress > 66)
    return ProgressColorHex[ProgressColor.GreenOpacity];

  return ProgressColorHex[ProgressColor.None];
} 



export const getTypesFromArray = (arr: any[]|any, labels: any) : string => {
  if(!arr) return '';
  return Array.isArray(arr)
    ? arr.map((type: any) => labels[type.id]).join(', ')
    : labels[arr.id]
}



export const getIds = (arr: any[]) : number[] => {
  if(!arr) return [];
  return arr && arr
    .filter((item: any) => !!item)
    .map((item: any) => item.id)
}



export const groupBy = (arr: any[], key: string) : any[] => {
  if(!arr || !arr.length) return [];
  return arr.reduce((rv, item) => {
    (rv[item[key]] = rv[item[key]] || []).push(item);
    return rv;
  }, {});
};



export const acronym = (words: string|string[]) : string => {
  if(!words) return '';
  return Array.isArray(words)
    ? words.map((word: string) => word[0]).join('')
    : words.split(' ').map((word: string) => word[0]).join('')
}



export const pluralize = (length: number, word: string) => {
  return length === 1 ? word : `${word}s`;
}



export const commify = (str: string): string => {
  const match = str.match(/^-?\d+(?:\.\d{0,2})?/);
  return !!match?.length ? match[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
}



export const formatNumber = (num: number | string | undefined, toFixedAmount?: number): string => {
  if(!num) return '';

  if(toFixedAmount !== undefined && typeof num === 'number') {
    num = (+num).toFixed(toFixedAmount)
  }

  return commify(`${num}`)
}



export const getWords = (str: string) : string[] => {
  return str.toLowerCase().split(' ')
}



export function formatDate(timestamp: number|string|undefined, monthShort?: boolean, isUTC?: boolean) {
  if(!timestamp) return '';
  return isUTC
    ? moment.utc(timestamp).format('D MMMM YYYY')
    : moment(timestamp).format('D MMMM YYYY')
}



export function formatDateTime(timestamp: number|string|undefined) {
  return !!timestamp
    ? moment(timestamp).format(`D MMM YYYY, HH:mm`)
    : ''
}



export function formatTime(date: string) {
  if(!date) return '';

  const newDate = new Date(date);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
}



export const formatDateForLabel = (timestamp: string) : string => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  return `${day < 10 ? `0${day}` : day} ${month}`
}



export const formatMonthHeader = (year, month) : string => {
  const date = new Date(year, month, 1)
  return date.toLocaleString('default', { month: 'long' })
}



export const formatAgoTime = (timestamp: string) : string => {
  return moment(timestamp).fromNow();
}



export const prefixify = (str: string, prefix?: string, nestedPrefix?: string) : string => {
  return `${prefix ?? ''}${nestedPrefix ?? ''}${str}`
}



export const isCP = (type: any): boolean => {
  return !!type?.is_cp
}



export const isRoute = (path: any, exact: boolean = false, customRoute: string = ''): boolean => {
  return !!matchPath(customRoute || window.location.pathname, {path, exact, strict: false})
}



export const getDateFormat = (format?: DateFormat): string => {
  return DateFormatFormula[format || DateFormat.DateTime]
}



export const getNestedValue = (path: string, object: any): any => {
  return path.split('.').reduce((prev, curr) => prev && prev[curr], object)
}



export const randomId = (length: number = 15): string => {
  var s = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  return Array.apply(null, Array(length)).map(function() { return s.charAt(Math.floor(Math.random() * s.length)); }).join('');
}



export const goToOutlook = (): void => {
  const { pathname, search } = window.location;
  const encodedPath = encodeURI(`${pathname}${search}`)
  const url = `${API_URL}/auth/login_with_ms?state=${encodedPath}`;
  window.open(url);
}



export const getCompaniesCounter = (people?: any[]): any => {
  if(!people?.length) return null;

  const grouped = groupBy(people, 'company');
  const companiesLength = Object.keys(grouped).length;
  console.log(Object.keys(grouped));
  return `, ${companiesLength} ${companiesLength === 1 ? 'company' : 'companies'} selected`
}



export const sortByBool = (arr: any[], field: string): any[] => {
  return arr.sort((a: any, b: any) => 
    (a[field] === b[field]) ? 0 : (a[field] ? -1 : 1)
  )
}




const idsToObjects = (ids?: number[], field: string = ''): any[] => {
  if(!ids) return [];
  const types = store.getState().types[field];
  if(!types) return [];

  return ids.map((id: number) => types.find((type: any) => type.id === id))
    .filter((item: any) => !!item)
}



export const getCategoryLabel = (person: any): string => {
  if(!person?.categories?.length) return '';


  const categories = person.categories?.[0]?.id ? person.categories : idsToObjects(person.categories, 'categories');
  const subcategories = person.subcategories?.[0]?.id ? person.subcategories : idsToObjects(person.subcategories, 'subcategories');


  return categories
    .map((cat: any) => {
      const subCats = subcategories.filter((subCat: any) => subCat.category === cat.id);

      return !!subCats.length
        ? subCats.map((subCat: any) => `${subCat.name ?? ''} ${cat.name ?? ''}`)
        : cat.name
    })
    .flat()
    .join(', ')
}



export const goTo = (e: MouseEvent<HTMLElement>, path: string, history: any): void => {
  if(e.preventDefault && e.stopPropagation) {
    e.preventDefault();
    e.stopPropagation();
  }
  

  if(e.ctrlKey || e.metaKey) {
    window.open(path, '_blank');
    return
  }

  history.push?.(path, { backURL: window.location.pathname })
}



export const sortByName = (a: any, b: any): number => {
  if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0
}



export const formatAutocompleteItems = (items: any[], type: AutocompleteType): SelectItem[] => {
  let newItems = [] as SelectItem[];

  switch(type){

    case AutocompleteType.People:
      newItems = items.map((person: any) => ( {id: person.id, name: getUsername(person) }))
      break;


    case AutocompleteType.Companies:
      newItems = items.map((org: any) => ({ name: org.company }))
      break;


    case AutocompleteType.Projects:
      newItems = items.map((item: any) => ({ id: item.id, name: item.name }))
      break;


    case AutocompleteType.Countries:
      newItems = items.map((item: any) => ({ name: item.name }))
      break;


    case AutocompleteType.Tags:
      newItems = items.map((item: string) => ({ name: item }))
      break;
  }


  return newItems.sort(sortByName)
}



export const assignDefaultSort = (columns: TableColumn[], manageColumns: ManageableTableColumn[]): any => {
  let defaultColumns = columns
    .filter((item: TableColumn) => item.defaultSortOrder !== undefined)
    .sort((a: TableColumn, b: TableColumn) => !!(a.defaultSortOrder && b.defaultSortOrder) ? a.defaultSortOrder - b.defaultSortOrder : 0)
    .map((item: TableColumn) => item.type)

  

  const firstManageColumn = manageColumns.find((item: ManageableTableColumn) => !!item.search);
  if (!defaultColumns.length && !!firstManageColumn) {
    const firstColumn = columns.find((item: TableColumn) => item.type === firstManageColumn.type);
    if(!firstColumn) return manageColumns;

    return manageColumns.map((item: ManageableTableColumn) => item.type === firstColumn.type
      ? {
          ...item,
          sortOrder: 1,
          sortDirection: firstColumn?.defaultSortDirection ?? SortDirection.ASC
        }
      : {
          ...item,
          sortOrder: undefined,
          sortDirection: undefined
        }
    )
  }


  return manageColumns.map((item: ManageableTableColumn) => ({
    ...item,
    sortOrder: defaultColumns.includes(item.type) ? item.defaultSortOrder : undefined,
    sortDirection: defaultColumns.includes(item.type) ? (item.defaultSortDirection ?? SortDirection.ASC) : SortDirection.ASC
  }))
}



export const formatISort = (columns: ManageableTableColumn[]): ISort[] => (
  columns
    .filter((item: ManageableTableColumn) => item.sortOrder !== undefined)
    .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
    .map((item: ManageableTableColumn) => ({
      type: item.type,
      column: item.search,
      direction: item.sortDirection ?? SortDirection.ASC
    }) as ISort)
)