import React, { useEffect, useCallback, useMemo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getIds, groupBy } from '../../../utils/utils';
import { ProviderColorHex } from '../../../shared/types';
import { useTrackerSummary } from './hooks/tracker-hooks';
import { setSummary } from '../../../actions/projects/providers';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '22px 0',
    marginBottom: 30,
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  itemWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #d9e4e8',
    justifyContent: 'center',
    padding: '1px 0',
    transition: 'opacity .1s ease',
    '&:last-child': {
      borderRight: 0
    }
  },
  itemWrapperDisabled: {
    '& $number, & $label': {
      color: '#90a0a7 !important'
    }
  },
  number: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '23px',
    color: '#657274',
    backgroundColor: 'transparent !important',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  label: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: 0.53,
    color: '#657274',
    textTransform: 'uppercase'
  },
  contacted: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 10px',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  counts: {
    display: 'flex',
    width: 'max-content',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    marginRight: 15,
  },
  names: {
    display: 'flex',
    width: 'max-content',
    flexDirection: 'column'
  },
  print: {
    marginTop: 15,
    border: '1px solid #d9e4e8',
    boxShadow: 'none',
    padding: '15px 0',
  }
})



  
const total = (obj: any) => {
  if(!obj) return 0;

  const values = Object.values(obj)
  return values.length
    ? values.reduce((acc: any, curr: any) => acc + curr)
    : 0
}



interface IProps extends WithStyles<typeof styles> {
  summary?: any[];
}

const TrackerHeader = ({ classes, summary: propSummary }: IProps) => {
  const dispatch = useDispatch();
  const types = useSelector((state: any) => state.types.provider_relation_types);
  const { filteredProviders, filters, providers } = useSelector((state: any) => state.projectProviders);
  
  
  
  const statusesIds = useMemo(() => getIds(filters.statuses), [filters.statuses]);
  const companiesSummary = useTrackerSummary(true);


  console.log(companiesSummary)


  const contactsSummary = useMemo(() => {
    const grouped = groupBy(providers, 'state');
    return Object.fromEntries(Object.entries(grouped).map(([company, values]: any) => {
      return [company, values.length]
    }))
  }, [filteredProviders])






  const totalSummary = useMemo(() => {
    const totalCompanies = total(companiesSummary);
    const totalContacts = total(contactsSummary);

    return {
      counts: [ totalCompanies, totalContacts ],
      names: [
        totalCompanies === 1 ? 'Company' : 'Companies',
        totalContacts === 1 ? 'Contact' : 'Contacts'
      ]
    }
  }, [contactsSummary, companiesSummary])



  const summary = useMemo(() => (propSummary ?? [
    totalSummary,

    ...types.map((curr: any) => ({
      color: ProviderColorHex[curr.id],
      active: !statusesIds.length || statusesIds.includes(curr.id),
      counts: [ companiesSummary?.[curr.id] ?? 0, contactsSummary?.[curr.id] ?? 0 ],
      names: [ curr.name, curr.name ]
    }))


  ]),  [totalSummary, types, contactsSummary, companiesSummary, propSummary])




  const dispatchSetSummary = (newSummary: any[]): any => {
    return dispatch(setSummary(newSummary))
  }




  useEffect(() => {
    dispatchSetSummary(summary)
  }, [summary])





  return useMemo(() => (
    <div className={classNames(
      classes.root,
      'tracker-header-print'
    )}>

      {summary.map((item: any, index: number) => (
        <div
          key={index}
          className={classNames(
            classes.itemWrapper,
            {[classes.itemWrapperDisabled]: item.active !== undefined && !item.active}
          )}
        >
          <div className={classes.item}>

              <div className={classes.itemRow}>

                <div className={classes.counts}>
                  {item.counts.map((count: number, idx: number) => (
                    <span key={idx} className={classes.number} style={item.color}>
                      {count}
                    </span>
                  ))}
                </div>


                <div className={classes.names}>
                  {item.names.map((name: string, idx: number) => (
                    <span key={idx} className={classes.label}>{name}</span>
                  ))}
                </div>
                
              </div>

          </div>
        </div>
      ))}

    </div>
  ), [summary])
}


export default withStyles(styles)(TrackerHeader)